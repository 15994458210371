import io from "socket.io-client";

const state = {
  socket: null, // Instância do socket
  isConnected: false, // Estado de conexão
  inQueue: false, // Se o cliente está na fila
  inAttendance: false, // Se o chat está ativo
  attendance: null, // Objeto de atendimento atual
  messages: [], // Histórico de mensagens do chat
  lastUpdatedQueue: null,
  code: null,
};

const mutations = {
  SET_SOCKET(state, socket) {
    state.socket = socket;
  },
  SET_CONNECTED(state, status) {
    state.isConnected = status;
  },
  SET_IN_QUEUE(state, status) {
    state.inQueue = status;
  },
  SET_IN_ATTENDANCE(state, status) {
    state.inAttendance = status;
    state.inQueue = false;
  },
  SET_ATTENDANCE(state, attendance) {
    state.attendance = attendance;
  },
  SET_MESSAGES(state, messages) {
    state.messages = messages;
  },
  ADD_MESSAGE(state, data) {
    const { attendance } = state;
    const { author, createdAt, text } = data;
    const authorID = author?._id;

    const isRequester = !authorID || authorID === attendance.requester._id;

    state.messages.push({
      me: isRequester,
      createdAt,
      author: {
        name: author.name,
        image: "",
      },
      message: text,
    });
  },
  CLEAR_MESSAGES(state) {
    state.messages = [];
  },
  FINISH_ATTENDANCE(state) {
    state.attendance = null;
    state.inAttendance = false;
    state.inQueue = false;
    state.lastUpdatedQueue = null;
    state.messages = [];
  },
  TRANSFER_ATTENDANCE(state, data) {
    state.attendance = data;
    state.inAttendance = false;
    state.inQueue = true;
    state.lastUpdatedQueue = null;
  },
  LAST_UPDATED_QUEUE(state, data) {
    if (state.attendance?.desk?._id === data.deskID) {
      state.lastUpdatedQueue = new Date().getTime();
    }
  },
  SET_CODE(state, data) {
    state.code = data;
  },
};

async function validateSocketConnectionExistence({ state, dispatch }) {
  if (!state.isConnected) {
    await dispatch("connectSocket");
  }
}

const actions = {
  // Conecta o socket ao servidor
  connectSocket({ state, commit }) {
    return new Promise((resolve) => {
      if (!state.socket) {
        const socket = io(process.env.VUE_APP_SOCKET_URL, {
          autoConnect: false,
          auth: { xApiKey: process.env.VUE_APP_API_KEY },
        });

        socket.connect();
        socket.on("connect", () => {
          console.log("Socket connected");
          commit("SET_CONNECTED", true);
          commit("SET_SOCKET", socket);

          console.log("attendance", state.attendance);

          if (state.attendance?.protocol) {
            socket.emit("attendance:reconnect", {
              protocol: state.attendance?.protocol,
            });
          }

          resolve();
        });

        socket.on("disconnect", () => {
          console.log("Socket disconnected");
          commit("SET_CONNECTED", false);
          commit("SET_SOCKET", null);
        });

        // Configuração dos listeners
        socket.on("attendance:started", (data) => {
          console.log("attendance:started", data);
          commit("SET_ATTENDANCE", data);
          commit("SET_IN_ATTENDANCE", true);
          commit("SET_IN_QUEUE", false);
          // commit("CLEAR_MESSAGES");
        });

        socket.on("attendance-messages", (data) => {
          commit("ADD_MESSAGE", data);
        });

        socket.on("attendance:finished", (data) => {
          commit("FINISH_ATTENDANCE");
          commit("SET_CODE", data?.code);
        });

        socket.on("attendance:canceled", () => {
          commit("FINISH_ATTENDANCE");
        });

        socket.on("attendance:queue", (data) => {
          commit("LAST_UPDATED_QUEUE", data);
        });

        socket.on("attendance:transferred", (data) => {
          commit("TRANSFER_ATTENDANCE", data);
        });

        socket.on("attendance:reconnected", (data) => {
          console.log("attendance:reconnected", data);

          commit("SET_ATTENDANCE", data);
          commit("LAST_UPDATED_QUEUE", { deskID: data?.desk?._id });

          if (data.messages) {
            commit("CLEAR_MESSAGES");
            data.messages.forEach((message) => {
              commit("ADD_MESSAGE", message);
            });
          }
        });

        socket.on("pong", () => {
          console.log("pong");
        });
      }
    });
  },

  // Desconecta o socket do servidor
  disconnectSocket({ state, commit }) {
    if (state.socket) {
      state.socket.disconnect();
      commit("SET_CONNECTED", false);
      commit("SET_SOCKET", null);
    } else {
      console.log("Socket já está desconectado ou não inicializado");
    }
  },

  // Cria um novo atendimento
  async createNewAttendance({ state, dispatch }, { callback }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:new", callback);
  },

  // Requisita um atendimento e entra para a fila
  async requestAttendance({ state, dispatch }, { data, callback }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:request", data, callback);
  },

  // Cancela o atendimento
  async cancelAttendance({ state, dispatch }, { data, callback }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:cancel", data, callback);
  },

  // Cliente ingressa em uma sessão existente (ex: após refresh)
  async joinAttendance({ state, dispatch }, { data }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:join", data);
  },

  // Envia uma nova mensagem no chat do atendimento
  async sendMessage({ state, dispatch }, { data, callback }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance-messages:new", data, callback);
  },

  // Finaliza o atendimento
  async finishAttendance({ state, dispatch }, { data, callback }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:finish", data, callback);
  },

  // Marca o atendimento como inativo
  async inactivateAttendance({ state, dispatch }, { data }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:inactive", data);
  },

  async dialogAttendance({ state, dispatch }, { data, callback }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("attendance:dialog", data, callback);
  },

  async pingEmit({ state, dispatch }) {
    await validateSocketConnectionExistence({ state, dispatch });

    return state.socket.emit("ping");
  },

  async setMessages({ commit }, data) {
    commit("SET_MESSAGES", data);
  },

  async setAttendance({ commit }, data) {
    commit("SET_ATTENDANCE", data);
  },

  setInQueue({ commit }, data) {
    commit("SET_IN_QUEUE", data);
  },

  setCode({ commit }, data) {
    commit("SET_CODE", data);
  },

  addMessage({ commit }, data) {
    commit("ADD_MESSAGE", data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
