<template>
  <v-container fill-height class="justify-center">
    <v-row justify="center">
      <v-col cols="10" md="6" lg="4" xl="3">
        <v-card
          class="pa-8"
          height="100%"
          width="100%"
          flat
          :disabled="disableCard"
          :elevation="3"
          @click="handleCaptcha()"
        >
          <v-row class="mb-2" justify="center">
            <v-col cols="8">
              <v-img :src="require('@/assets/new_figure.png')" />
            </v-col>
          </v-row>

          <div class="text-h6 font-weight-light text-center">
            Iniciar Novo Atendimento
          </div>
        </v-card>
      </v-col>

      <!-- <v-col cols="7" md="6" lg="4" xl="3">
        <v-card
          class="pa-8"
          height="100%"
          :disabled="disableCard"
          :elevation="3"
          flat
          @click="handleSelectUnity()"
        >
          <v-row class="mb-2" justify="center">
            <v-col cols="10">
              <v-img :src="require('@/assets/queue_figure.png')" />
            </v-col>
          </v-row>

          <div class="text-h6 font-weight-light text-center">Audiências</div>

          <div class="text-body-1 font-weight-light text-center mt-n1">
            Entrar no corredor de espera
          </div>
        </v-card>
      </v-col> -->
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="8" xl="7">
        <div class="text-body-1 font-weight-light text-center">
          Use a opção acima para iniciar um novo atendimento. Este canal foi
          criado para facilitar o contato direto entre cidadãos, advogados(as).
        </div>
      </v-col>
    </v-row>

    <!-- O Overlay de loading -->
    <LoadingOverlay :loading="loading.overlay" />

    <v-dialog v-model="unityDialog" max-width="25rem" persistent>
      <v-card class="pa-6 rounded-lg">
        <div class="d-flex align-center mb-4">
          <div class="text-body-1 font-weight-bold">Selecione uma unidade</div>

          <v-spacer />

          <v-btn icon @click="cancelUnity()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid.unity" ref="formUnity" autocomplete="off">
          <v-select
            v-model="form.unity"
            :items="unities"
            placeholder="Selecione uma unidade"
            item-value="name"
            item-text="name"
            :rules="_rules"
            outlined
          ></v-select>
        </v-form>

        <v-btn
          class="text-none"
          color="primary"
          @click="submitUnity()"
          block
          large
        >
          Confirmar
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de Captcha -->
    <v-dialog v-model="captchaDialog" max-width="19.75rem" persistent>
      <v-card v-if="captchaDialog" class="pa-2">
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="_siteKey"
          @verify="onVerify"
          @expired="onExpired"
        ></vue-recaptcha>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { handleAlert, formatDocument, showError } from "@/utils";
import { getCourts } from "@/services/courts";
import { VueRecaptcha } from "vue-recaptcha";
import LoadingOverlay from "@/components/loadingOverlay/LoadingOverlay.vue";

export default {
  name: "Home",

  components: {
    LoadingOverlay,
    VueRecaptcha,
  },

  data() {
    return {
      loading: {
        overlay: false,
        unities: false,
      },
      disableCard: false,
      validCaptcha: false,
      unityDialog: false,
      unities: [],
      valid: {
        unity: false,
      },
      form: {
        unity: "",
      },
      showForm: false,
      dialog: false,
      captchaDialog: false,
      dialogTitle: "Escolha abaixo a instância e o balcão de atendimento",
    };
  },

  computed: {
    ...mapState("socket", ["attendance"]),

    _siteKey() {
      return process.env.VUE_APP_GCAPTCHA_PUBLIC_KEY;
    },

    _rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  mounted() {
    this.handleQuerys();
  },

  methods: {
    ...mapActions("socket", [
      "createNewAttendance",
      "setAttendance",
      "setMessages",
    ]),
    ...mapActions(["setPreSelection"]),

    handleCaptcha() {
      this.captchaDialog = true;
    },

    handleSelectUnity() {
      this.getCourts();
      this.unityDialog = true;
    },

    submitUnity() {
      if (!this.$refs.formUnity.validate()) return;

      const unity = this.form.unity
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const newName = unity
        .toLowerCase()
        .replace(/[^\w\s]/g, "")
        .replace(/\s+/g, "-");

      window.location.href = `https://audiencias-app-673194685875.us-central1.run.app/${newName}/entrar`;
    },

    cancelUnity() {
      this.unityDialog = false;
      this.form.unity = "";
    },

    async getCourts() {
      try {
        this.loading.unities = true;

        const payload = {
          limit: 100,
          offset: 0,
          enableAudience: true,
        };

        const { data } = await getCourts(payload);
        this.unities = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.unities = false;
      }
    },

    async handleStartChat() {
      console.log("attendance:new");

      await this.createNewAttendance({
        callback: (error, data) => {
          console.log("createNewAttendance:data", data);

          if (error) {
            console.log("createNewAttendance:error", error);
            throw new Error("Falha ao tentar conectar ao socket. error");
          }

          this.setMessages([]);
          this.setAttendance(data);

          this.$router.push("/chat-bot").catch(() => {});
        },
      });
    },

    onVerify(response) {
      if (!response) return (this.captchaDialog = false);
      this.validCaptcha = true;
      this.handleStartChat();
    },

    onExpired() {
      this.validCaptcha = false;
      this.captchaDialog = false;
    },

    async handleQuerys() {
      const query = this.$route.query;

      if (!query || !Object.keys(query).length) return;

      this.loading.overlay = true;

      const payload = {
        ...(query.deskID && { deskID: query.deskID }),
        ...(query.subgroupID && { subgroupID: query.subgroupID }),
        ...(query.groupID && { groupID: query.groupID }),
      };

      await this.setPreSelection(payload);
      if (Object.keys(payload).length) await this.handleStartChat();

      this.loading.overlay = false;
    },

    showError,

    handleAlert,

    formatDocument,
  },
};
</script>

<style src="./style.scss" lang="scss" />
