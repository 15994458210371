<template>
  <v-container fill-height>
    <div class="d-flex flex-column fill-width">
      <v-row class="mb-2" justify="center">
        <v-col cols="6" md="3">
          <v-img :src="require('@/assets/queue_figure.png')" />
        </v-col>
      </v-row>

      <div v-if="loading" class="d-flex align-center justify-center py-6">
        <v-progress-circular indeterminate color="primary" />
      </div>

      <div v-else>
        <section v-if="closed">
          <div class="d-flex flex-column align-center text-center mb-4">
            <div class="text-h6 font-weight-regular mb-2">
              Serviço de Atendimento Indisponível
            </div>

            <v-row justify="center">
              <v-col cols="12" md="8">
                <div class="text-body-1 font-weight-light mb-2">
                  O serviço neste balcão de atendimento está fechado no momento.
                  Por favor, verifique nosso horário de funcionamento ou entre
                  em contato conosco em um horário alternativo. Agradecemos pela
                  compreensão.
                </div>
              </v-col>
            </v-row>
          </div>
        </section>

        <section v-else>
          <div class="d-flex flex-column align-center text-center mb-4">
            <div class="text-h6 font-weight-regular mb-2">
              Você está na fila de Atendimento
            </div>

            <div class="text-body-1 font-weight-light mb-2">
              Esta fila será atualizada em tempo real<br />
              conforme os atendimentos ocorram.
            </div>

            <div class="text-h6 font-weight-regular mb-2">
              Sua posição na fila: {{ queuePosition }}º
            </div>

            <div class="text-h6 font-weight-regular mb-2">
              Seu numero de protocolo é: {{ attendance?.protocol }}
            </div>
          </div>
        </section>

        <div class="d-flex justify-center mb-4">
          <v-btn
            class="text-none"
            color="primary"
            @click="finalizeAttendance()"
          >
            Cancelar
          </v-btn>
        </div>

        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-card class="white" flat>
              <v-row no-gutters>
                <v-col
                  v-for="(item, i) in _attendanceData"
                  :key="i"
                  class="pa-4"
                  cols="6"
                  md="4"
                >
                  <div class="d-flex align-center fill-height side-border pl-3">
                    <div class="d-flex flex-column">
                      <div class="text-caption mb-n1">
                        {{ item.label }}
                      </div>

                      <div class="text-body-1 font-weight-bold gray2--text">
                        {{ item.value }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getQueuePosition, getQueueTimeAvg } from "@/services/attendance";
import { handleAlert, handleFinishAttendance, showError } from "@/utils";
import { getCounterStatus } from "@/services/counters";
import moment from "moment";

export default {
  name: "Queue",

  data() {
    return {
      loading: true,
      closed: false,
      queueMode: false,
      queuePosition: 0,
      payload: {},
      averageTime: "--",
    };
  },

  created() {
    this.getCounterStatus();
  },

  beforeMount() {
    this.handlePosition();
  },

  computed: {
    ...mapState("socket", ["attendance", "lastUpdatedQueue"]),

    _attendanceData() {
      if (!this.attendance) {
        return [];
      }

      return [
        {
          label: this.$t("group"),
          value: this.attendance?.comarca?.name || "--",
        },
        {
          label: this.$t("subgroup"),
          value: this.attendance?.vara?.name || "--",
        },
        {
          label: this.$t("desk"),
          value: this.attendance?.desk?.name || "--",
        },
        {
          label: "Tempo Estimado para Atendimento",
          value: this.averageTime,
        },
      ];
    },
  },

  watch: {
    lastUpdatedQueue(value) {
      if (value) {
        this.handlePosition();
      }
    },
  },

  methods: {
    ...mapActions("socket", ["setAttendance"]),

    async getCounterStatus() {
      if (!this.attendance) {
        return;
      }
      this.loading = true;

      try {
        const { data } = await getCounterStatus(this.attendance.desk._id);

        if (data.isAvailable) {
          this.getPosition();
        } else {
          this.closed = true;
        }
      } catch (error) {
        console.log("Falha ao obter o status do balcão.", error);
        this.showError(error);
        this.closed = true;
      } finally {
        this.loading = false;
      }
    },

    async handlePosition() {
      await this.getPosition();
      await this.getQueueTimeAvg();
    },

    async getPosition() {
      this.loading = true;

      try {
        const { data } = await getQueuePosition(this.attendance._id);

        this.queuePosition = Number(data.position);
        this.queueMode = true;
      } catch (error) {
        console.log("Falha ao obter a posição na fila.", error);
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },

    async getQueueTimeAvg() {
      try {
        const startOfDay = moment()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss-03:00");

        const payload = {
          startDate: startOfDay,
          endDate: moment().format("YYYY-MM-DDTHH:mm:ss-03:00"),
          deskID: this.attendance.desk._id,
        };

        const { data } = await getQueueTimeAvg(payload);

        const avg =
          this.queuePosition > 0 ? data.avg * this.queuePosition : data.avg;
        const minutes = Math.floor(avg / 60000);
        const seconds = Math.floor((avg % 60000) / 1000);

        this.averageTime =
          minutes > 0 ? `${minutes} minuto(s)` : `${seconds} segundo(s)`;
      } catch (error) {
        console.log(error);
      }
    },

    getLabelClosestToAverage(data) {
      const totalCount = data.reduce((sum, item) => sum + item.count, 0);
      const averageCount = totalCount / data.length;

      let closestLabel = "";
      let closestDifference = Infinity;

      data.forEach((item) => {
        const difference = Math.abs(item.count - averageCount);
        if (difference < closestDifference) {
          closestDifference = difference;
          closestLabel = item.label;
        }
      });

      return closestLabel;
    },

    finalizeAttendance() {
      this.handleFinishAttendance();

      this.$router.push({ path: "/home" }).catch(() => {});
    },

    handleAlert,
    handleFinishAttendance,
    showError,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
